import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../Assets/electrical1.jpg";
import maintenance from "../Assets/maintenace.jpg";
import {
    faTools, faLightbulb, faPlug, faHardHat, faBolt, faNetworkWired
} from "@fortawesome/free-solid-svg-icons";
export default function Texture() {
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [city, setCity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      fullName,
      mobileNo,
      address,
      selectedService,
      dateTime,
      city,
    });
    setFullName("");
    setMobileNo("");
    setAddress("");
    setSelectedService("");
    setDateTime("");
    setCity("");
  };
  return (
    <>
      <div className="submenu-section">
        <div className="submenu-contaner">
          <img className="submenu-banner" src={banner}></img>
        </div>
        <section id="services" className="services">
          <div className="container">
            <h1>Electrical Maintenance Services</h1>

            <div className="service-animate-container">
              <div className="services-images-section">
                <img
                  className="service-images"
                  src={maintenance}
                  alt="House Cleaning Image"
                ></img>
                <div className="text-container">
                  <h4>Electrical Maintenance </h4>
                  <p>
                  Electrical maintenance is crucial for ensuring the safety, functionality, and efficiency of your home's electrical system. Neglecting electrical maintenance can lead to safety hazards, such as electrical fires, shocks, and damage to appliances. Here are some essential aspects of electrical maintenance:
                  </p>
                </div>
              </div>
              <div className="submenu-grid">
              <div className="sub-service-animate-section">
            <div className="sub-icon-box">
                <div className="icon">
                    <FontAwesomeIcon icon={faTools} />
                </div>
                <h4 className="title">
                    <a href="#">Electrical Repairs</a>
                </h4>
            </div>

            <div className="sub-icon-box">
                <div className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                </div>
                <h4 className="title">
                    <a href="#">Lighting Installation</a>
                </h4>
            </div>

            <div className="sub-icon-box">
                <div className="icon">
                    <FontAwesomeIcon icon={faPlug} />
                </div>
                <h4 className="title">
                    <a href="#">Appliance Installation</a>
                </h4>
            </div>

            <div className="sub-icon-box">
                <div className="icon">
                    <FontAwesomeIcon icon={faHardHat} />
                </div>
                <h4 className="title">
                    <a href="#">Safety Inspections</a>
                </h4>
            </div>

            <div className="sub-icon-box">
                <div className="icon">
                    <FontAwesomeIcon icon={faBolt} />
                </div>
                <h4 className="title">
                    <a href="#">Surge Protection Installation</a>
                </h4>
            </div>

            <div className="sub-icon-box">
                <div className="icon">
                    <FontAwesomeIcon icon={faNetworkWired} />
                </div>
                <h4 className="title">
                    <a href="#">Circuit Breaker Panel Upgrades</a>
                </h4>
            </div>
        </div>

                <div className="submenu-form">
                  <div className="contact-form-container">
                    <h2 className="submenu-text">
                      Enter Details to Request<br></br> No-Cost Callback
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Full Name:</label>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Mobile No:</label>
                        <input
                          type="text"
                          placeholder="Enter Mobile Number"
                          value={mobileNo}
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Address:</label>
                        <input
                          type="text"
                          placeholder="Enter Your Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Select Services:</label>
                        <select
                          value={selectedService}
                          onChange={(e) => setSelectedService(e.target.value)}
                        >
                          <option value="">-- Home Deep Cleaning --</option>
                          <option value="Service 1">Home Deep Cleaning</option>
                          <option value="Service 2">Carpentering Work</option>
                          <option value="Service 3">Plumbing Work</option>
                          <option value="Service 3">
                            Used Laptop & Desktop
                          </option>
                          <option value="Service 3">Carpet Shampooing</option>
                          <option value="Service 3">Chair Shampooing</option>
                          <option value="Service 3">Sofa Shampooingk</option>
                          <option value="Service 3">Marble polishing</option>
                          <option value="Service 3">
                            Industrial Deep Cleaning
                          </option>
                          <option value="Service 3">Sales & Services</option>
                          <option value="Service 3">Plumbing Work</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Date & Time:</label>
                        <input
                          type="datetime-local"
                          value={dateTime}
                          onChange={(e) => setDateTime(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>City:</label>
                        <input
                          type="text"
                          placeholder="Enter Your City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="submit-button">
                        Submit Now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
