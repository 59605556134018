import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../Assets/banner1.jpg";
import corporate from "../Assets/corporate.jpg";
import { Link } from "react-router-dom";
import {
  faSprayCan,
  faTools,
  faShower,
  faCouch,
  faChair,
  faPaintRoller,
} from "@fortawesome/free-solid-svg-icons";

export default function Corporate() {
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [city, setCity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      fullName,
      mobileNo,
      address,
      selectedService,
      dateTime,
      city,
    });
    setFullName("");
    setMobileNo("");
    setAddress("");
    setSelectedService("");
    setDateTime("");
    setCity("");
  };
  return (
    <>
      <div className="submenu-section">
        <div className="submenu-contaner">
          <img className="submenu-banner" src={banner}></img>
        </div>
        <section id="services" className="services">
          <div className="container">
            <h1>Corporates & Residential Services</h1>

            <div className="service-animate-container">
              <div className="services-images-section">
                <img
                  className="service-images"
                  src={corporate}
                  alt="House Cleaning Image"
                ></img>
                <div className="text-container">
                  <h4>Corporates & Residential</h4>
                  <p>
                    Corporates and residential services cater to two distinct
                    yet interconnected facets of modern life. For corporates,
                    the emphasis often lies in efficiency, professionalism, and
                    seamless operations. From office spaces to tech solutions,
                    corporates rely on a spectrum of services to optimize
                    productivity and foster growth.On the other hand,
                    residential services focus on enhancing the quality of life
                    within homes and communities. This encompasses everything
                    from household maintenance and repairs to lifestyle
                    amenities and community events.
                  </p>
                </div>
              </div>
              <div className="submenu-grid">
                <div className="sub-service-animate-section">
                  <div className="sub-icon-box">
                    <Link to="/cleaning">
                      <div className="icon">
                        <FontAwesomeIcon icon={faSprayCan} />
                      </div>
                      <h4 className="title">
                        <a href="#">Home Cleaning</a>
                      </h4>
                    </Link>
                  </div>

                  <div className="sub-icon-box">
                    <Link to="/carpet">
                      <div className="icon">
                        <FontAwesomeIcon icon={faTools} />
                      </div>
                      <h4 className="title">
                        <a href="#">Carpentering Work</a>
                      </h4>
                    </Link>
                  </div>

                  <div className="sub-icon-box">
                    <Link to="/plumbing">
                      <div className="icon">
                        <FontAwesomeIcon icon={faShower} />
                      </div>
                      <h4 className="title">
                        <a href="#">Plumbing Work</a>
                      </h4>
                    </Link>
                  </div>

                  <div className="sub-icon-box">
                    <Link to="/sofashapooing">
                      <div className="icon">
                        <FontAwesomeIcon icon={faCouch} />
                      </div>
                      <h4 className="title">
                        <a href="#">Sofa Cleaning</a>
                      </h4>
                    </Link>
                  </div>
                  <div className="sub-icon-box">
                    <Link to="/chair shampooing">
                      <div className="icon">
                        <FontAwesomeIcon icon={faChair} />
                      </div>
                      <h4 className="title">
                        <a href="#">Chair Shampooing</a>
                      </h4>
                    </Link>
                  </div>
                  <div className="sub-icon-box">
                    <Link to="/interior">
                      <div className="icon">
                        <FontAwesomeIcon icon={faPaintRoller} />
                      </div>
                      <h4 className="title">
                        <a href="#">Interior Painting</a>
                      </h4>
                    </Link>
                  </div>
                </div>
                <div className="submenu-form">
                  <div className="contact-form-container">
                    <h2 className="submenu-text">
                      Enter Details to Request<br></br> No-Cost Callback
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Full Name:</label>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Mobile No:</label>
                        <input
                          type="text"
                          placeholder="Enter Mobile Number"
                          value={mobileNo}
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Address:</label>
                        <input
                          type="text"
                          placeholder="Enter Your Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Select Services:</label>
                        <select
                          value={selectedService}
                          onChange={(e) => setSelectedService(e.target.value)}
                        >
                          <option value="">-- Home Deep Cleaning --</option>
                          <option value="Service 1">Home Deep Cleaning</option>
                          <option value="Service 2">Carpentering Work</option>
                          <option value="Service 3">Plumbing Work</option>
                          <option value="Service 3">
                            Used Laptop & Desktop
                          </option>
                          <option value="Service 3">Carpet Shampooing</option>
                          <option value="Service 3">Chair Shampooing</option>
                          <option value="Service 3">Sofa Shampooingk</option>
                          <option value="Service 3">Marble polishing</option>
                          <option value="Service 3">
                            Industrial Deep Cleaning
                          </option>
                          <option value="Service 3">Sales & Services</option>
                          <option value="Service 3">Plumbing Work</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Date & Time:</label>
                        <input
                          type="datetime-local"
                          value={dateTime}
                          onChange={(e) => setDateTime(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>City:</label>
                        <input
                          type="text"
                          placeholder="Enter Your City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="submit-button">
                        Submit Now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
