import React,{BrowserRouter as Router, Routes,Route} from "react-router-dom";
import Navbar from "../src/components/Navbar/Navbar.js";
import Main from "../src/components/Main/Main.js"
import Footer from "./components/Footer.js/Footer.js";
import DeepCleaning from "./components/Navbar/components/DeepCleaning/DeepCleaning.js";
import Carpentering from "./components/Navbar/components/Carpentering/Carpentering.js";
import HomePainting from "./components/Navbar/components/HomePainting/HomePainting.js";
import ElectricalWorks from "./components/Navbar/components/ElecticalWorks/ElectricalWorks.js";
import Corporate from "./components/Navbar/components/Corporate/Corporate.js";
import Plumbing from "./components/Navbar/components/Plumbing/Plumbing.js";
import Laptop from "./components/Navbar/components/Laptop/Laptop.js";
import Carpet from "./components/Navbar/components/Carpet/Carpet.js";
import Chairshampooing from "./components/Navbar/components/Chairshampooing/Chairshampooing.js";
import SofaShampooing from "./components/Navbar/components/SofaShampooing/SofaShapooing.js";
import MarblePolishing from "./components/Navbar/components/MarbleShampooing/MarbleShampooing.js";
import Industrial from "../src/components/Navbar/components/Industrial/Industrial.js"
import Sales from "./components/Navbar/components/Sales/Sales.js";
import Interior from "./components/Navbar/components/Interior/Interior.js";
import Exterior from "./components/Navbar/components/Exterior/Exterior.js";
import Vacant from "./components/Navbar/components/Vacant/Vacant.js";
import Texture from "./components/Navbar/components/Texture/Texture.js";
import Maintenance from "./components/Navbar/components/Maintenance/Maintenance.js";
import Wiring from "./components/Navbar/components/Wiring/Wiring.js";
import Circuit from "./components/Navbar/components/Circuits/Circuits.js";
import Facility from "./components/Navbar/components/Facility/Facility.js";
import Manpower from "./components/Navbar/components/Manpower/ManPower.js";
import About from "./components/About/About.js";
import Fabrication from "./components/Navbar/components/Fabrication/Fabrication.js";
function App() {
  return (
    <>
   <Router>
    <Navbar/>
    <Routes>
    <Route path="/" element={<Main/>}></Route>
    <Route path="about" element={<About/>}></Route>
    <Route path="cleaning" element={<DeepCleaning/>}></Route>
    <Route path="carpentering" element={<Carpentering/>}></Route>
    <Route path="home painting" element={<HomePainting/>}></Route>
    <Route path="electrical" element={<ElectricalWorks/>}></Route>
    <Route path="corporate" element={<Corporate/>}></Route>
    <Route path="plumbing" element={<Plumbing/>}></Route>
    <Route path="grouting" element={<Laptop/>}></Route>
    <Route path="carpet" element={<Carpet/>}></Route>
    <Route path="chair shampooing" element={<Chairshampooing/>}></Route>
    <Route path="sofashapooing" element={<SofaShampooing/>}></Route>
    <Route path="marblepolishing" element={<MarblePolishing/>}></Route>
    <Route path="industrial" element={<Industrial/>}></Route>
    <Route path="sales" element={<Sales/>}></Route>
    <Route path="interior" element={<Interior/>}></Route>
    <Route path="exterior" element={<Exterior/>}></Route>
    <Route path="vacant" element={<Vacant/>}></Route>
    <Route path="texture" element={<Texture/>}></Route>
    <Route path="maintenance" element={<Maintenance/>}></Route>
    <Route path="rewiring" element={<Wiring/>}></Route>
    <Route path="circuit" element={<Circuit/>}></Route>
    <Route path="facility" element={<Facility/>}></Route>
    <Route path="manpower" element={<Manpower/>}></Route>
    <Route path="fabrication" element={<Fabrication/>}></Route>
    </Routes>
    <Footer/>
   </Router>
    </>
  );
}

export default App;
