import React, { useState, useEffect } from "react";
import "./Slider.css";
import banner1 from "../Assets/banner1.jpg";
import banner2 from "../Assets/banner2.jpg";
import banner3 from "../Assets/banner3.jpg";
import banner4 from "../Assets/banner4.jpg";
const banners = [
  { src: banner1 },
  { src: banner2 },
  { src: banner3 },
  { src: banner4 },
];

const BannerSlider = () => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDescription(true);
    });

    return () => clearTimeout(timer);
  }, [currentBanner]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowDescription(false);
      setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="banner-container">
      <img
        src={banners[currentBanner].src}
        alt="Banner"
        className="banner-image"
      />
      {showDescription && (
        <p className="banner-description">{banners[currentBanner].content}</p>
      )}
    </div>
  );
};

export default BannerSlider;
