import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import whatsapp from "../Assets/whatsapp.png";
function Footer() {
    const handleWhatsAppClick = () => {
        const phoneNumber = "9108224224"; // Replace with the desired phone number
        const message = encodeURIComponent("Hello, this is a pre-filled message.");
    
        window.open(
          `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`,
          "_blank"
        );
      };
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>R2 Cleaning Services</h4>
                    <p>No. 44, Ejipura Main Road, Near Greenview Hospital, Bangalore-47.</p>
                </div>
                <div className="footer-section">
                    <h4>Contact Us</h4>
                    <p>Email: Info@r2cleaningservices.com</p>
                    <p>Phone: 9108224224</p>
                </div>
                <div className="footer-section">
                    <h4>Services</h4>
                    <p><Link to="/corporate">Corporate & Residencial Services</Link></p>
                    <p><Link to="/home painting">Home Painting Services</Link></p>
                    <p><Link to="/electrical">Electrical Services</Link></p>
                </div>
                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <p><Link to="/cleaning">Cleaning Services</Link></p>
                    <p><Link to="/interior">Painting Services</Link></p>
                    <p><Link to="/maintenance">Electrical Services</Link></p>
                </div>
                <div className="footer-section">
                    <h4>Follow Us</h4>
                    <a style={{padding:'10px'}} href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon  icon={faFacebookF} /> 
                    </a>
                    {/* <a href="https://web.whatsapp.com/" target="_blank" rel="noopener noreferrer"> 
                        <FontAwesomeIcon icon={faWhatsapp} style={{paddingLeft:'12px'}} /> WhatsApp
                    </a> */}
                    <a style={{padding:'10px'}} href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} /> 
                    </a>
                </div>
            </div>
            <div className="copyright">
                    <p>&copy; 2024 R2 Cleaning Services. All rights reserved.</p>
                
                </div>
                <div class="icon-container">
    <img onClick={handleWhatsAppClick} src={whatsapp} alt="WhatsApp icon" class="whatsapp-icon"></img>
</div>

        </footer>
        
    );
}

export default Footer;
