import React from 'react'
import Slider from "../Slider/Slider"
import ServiceIcons from '../ServiceIcons/ServiceIcons'
export default function Main() {
  return (
    <>
   <Slider/>
   <ServiceIcons/>
    </>
  )
}
