import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../Assets/banner2.jpg";
import vacant from "../Assets/vacant.jpg";
import {
  faBrush,
  faVectorSquare,
  faLongArrowAltUp,
  faLeaf,
  faHome,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
export default function Vacant() {
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [city, setCity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      fullName,
      mobileNo,
      address,
      selectedService,
      dateTime,
      city,
    });
    setFullName("");
    setMobileNo("");
    setAddress("");
    setSelectedService("");
    setDateTime("");
    setCity("");
  };
  return (
    <>
      <div className="submenu-section">
        <div className="submenu-contaner">
          <img className="submenu-banner" src={banner}></img>
        </div>
        <section id="services" className="services">
          <div className="container">
            <h1>Vacant Painting Services</h1>

            <div className="service-animate-container">
              <div className="services-images-section">
                <img
                  className="service-images"
                  src={vacant}
                  alt="House Cleaning Image"
                ></img>
                <div className="text-container">
                  <h4>Vacant Painting</h4>
                  <p>
                    Vacant painting refers to the process of painting the
                    interior of an empty property. This approach is especially
                    favored by homeowners, landlords, and real estate
                    professionals looking to sell or rent out properties. The
                    absence of furniture and personal belongings offers several
                    advantages:
                  </p>
                </div>
              </div>
              <div className="submenu-grid">
                <div className="sub-service-animate-section">
                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faBrush} />
                    </div>
                    <h4 className="title">
                      <a href="#">Wall Painting</a>
                    </h4>
                  </div>

                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faVectorSquare} />
                    </div>
                    <h4 className="title">
                      <a href="#">Detailed Trimming Work</a>
                    </h4>
                  </div>

                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faLongArrowAltUp} />
                    </div>
                    <h4 className="title">
                      <a href="#">High Ceilings Painting</a>
                    </h4>
                  </div>

                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faLeaf} />
                    </div>
                    <h4 className="title">
                      <a href="#">Eco-Friendly Paint Options</a>
                    </h4>
                  </div>

                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faHome} />
                    </div>
                    <h4 className="title">
                      <a href="#">Vacant Painting</a>
                    </h4>
                  </div>
                </div>

                <div className="submenu-form">
                  <div className="contact-form-container">
                    <h2 className="submenu-text">
                      Enter Details to Request<br></br> No-Cost Callback
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Full Name:</label>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Mobile No:</label>
                        <input
                          type="text"
                          placeholder="Enter Mobile Number"
                          value={mobileNo}
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Address:</label>
                        <input
                          type="text"
                          placeholder="Enter Your Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Select Services:</label>
                        <select
                          value={selectedService}
                          onChange={(e) => setSelectedService(e.target.value)}
                        >
                          <option value="">-- Home Deep Cleaning --</option>
                          <option value="Service 1">Home Deep Cleaning</option>
                          <option value="Service 2">Carpentering Work</option>
                          <option value="Service 3">Plumbing Work</option>
                          <option value="Service 3">
                            Used Laptop & Desktop
                          </option>
                          <option value="Service 3">Carpet Shampooing</option>
                          <option value="Service 3">Chair Shampooing</option>
                          <option value="Service 3">Sofa Shampooingk</option>
                          <option value="Service 3">Marble polishing</option>
                          <option value="Service 3">
                            Industrial Deep Cleaning
                          </option>
                          <option value="Service 3">Sales & Services</option>
                          <option value="Service 3">Plumbing Work</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Date & Time:</label>
                        <input
                          type="datetime-local"
                          value={dateTime}
                          onChange={(e) => setDateTime(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>City:</label>
                        <input
                          type="text"
                          placeholder="Enter Your City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="submit-button">
                        Submit Now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
