import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../Assets/logo.png";
import menu from "../Navbar/components/Assets/menu-bar.png";
const toggleMenu = () => {
  var menu = document.querySelector(".navbar-menu");
  menu.classList.toggle("active");
};
function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // const toggleMenu = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };

  return (
    <>
      <div className="offer-section">
        <p className="blinking-text">
          Unlock Our Exclusive Offer: Get Rs.1000 FREE Gift Today & Enjoy 20%
          OFF Your First Cleaning Service!
        </p>
      </div>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/">
            <div className="navbar-logo">
              <img className="logo" src={logo}></img>
              <img
                className="menu-icon"
                onClick={toggleMenu}
                src={menu}
                style={{ width: "40px", height: "40px" }}
                alt="menu icon"
              />
            </div>
          </Link>

          <div className={`navbar-menu ${isMenuOpen ? "open" : ""}`}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/corporate" className="nav-link">
                  For Corporates & Residential <IoIosArrowDown />
                </Link>
                <div className="submenu">
                  <div class="column">
                    <h4>Home Cleaning</h4>
                    <ul>
                      <li>
                        <Link className="sub-menu-list" to="/cleaning">
                          Home Deep Cleaning
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/marblepolishing">
                          Marble polishing
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/grouting">
                          Grouting And Acid Wash
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div class="column">
                    {/* <h4>Furniture & Furnishings</h4> */}
                    <h4>Interior Design</h4>
                    <ul>
                      <li>
                        <Link className="sub-menu-list" to="/fabrication">
                        Fabrication ( Ms, SS and Alluminium)
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/carpet">
                          Carpet Cleaning
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/chair shampooing">
                          Chair Cleaning
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/sofashapooing">
                          Sofa Cleaning
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/carpentering">
                          Carpentering Work
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/plumbing">
                          Plumbing Work
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/industrial">
                          Industrial Deep Cleaning
                        </Link>
                      </li>
                      {/* <li>
                        <Link className="sub-menu-list" to="/sales">
                          Sales & Services
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/home painting" className="nav-link">
                  Home Painting <IoIosArrowDown />
                </Link>
                <div className="submenu">
                  <div class="column">
                    <ul>
                      <li>
                        <Link className="sub-menu-list" to="/interior">
                          Interior Painting
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/vacant">
                          Vacant Painting
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/exterior">
                          Exterior Painting
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/texture">
                          Interior Texture
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/electrical" className="nav-link">
                  Electrical Works <IoIosArrowDown />
                </Link>
                <div className="submenu">
                  <div class="column">
                    <ul>
                      <li>
                        <Link className="sub-menu-list" to="/maintenance">
                          Electrical Maintenance
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/rewiring">
                          Rewiring Works
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/circuit">
                          Fitting Circuit Breakers
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/facility" className="nav-link">
                  Facility Management Services <IoIosArrowDown />
                </Link>
                <div className="submenu">
                  <div class="column">
                    <ul>
                      <li>
                        <Link className="sub-menu-list" to="/facility">
                          Facility Management Services
                        </Link>
                      </li>
                      <li>
                        <Link className="sub-menu-list" to="/manpower">
                          Manpower Supply
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="menu-icon" onClick={toggleMenu}>
            <i className={`fas ${isMenuOpen ? "fa-times" : "fa-bars"}`}></i>
          </div> */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
