import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../Assets/carpenter2.jpg";
import carpenter from "../Assets/carpenter.jpg";
import {
  faHammer,
  faTools,
  faPaintRoller,
  faRuler,
  faScrewdriver,
} from "@fortawesome/free-solid-svg-icons";

export default function Carpentering() {
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [city, setCity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      fullName,
      mobileNo,
      address,
      selectedService,
      dateTime,
      city,
    });
    setFullName("");
    setMobileNo("");
    setAddress("");
    setSelectedService("");
    setDateTime("");
    setCity("");
  };
  return (
    <>
      <div className="submenu-section">
        <div className="submenu-contaner">
          <img className="submenu-banner" src={banner}></img>
        </div>
        <section id="services" className="services">
          <div className="container">
            <h1>Carpentering Works</h1>

            <div className="service-animate-container">
              <div className="services-images-section">
                <img
                  className="service-images"
                  src={carpenter}
                  alt="House Cleaning Image"
                ></img>
                <div className="text-container">
                  <h4>Carpenter</h4>
                  <p>
                    Carpentry, a craft as old as civilization itself, combines
                    skill, precision, and creativity to transform raw wood into
                    functional and often beautiful structures. From crafting
                    furniture to constructing homes, carpentry is foundational
                    to the world we live in.At its core, carpentry is about more
                    than just cutting wood. It's about understanding the
                    characteristics of different wood types, mastering tools
                    both ancient and modern, and possessing the vision to bring
                    designs to life.
                  </p>
                </div>
              </div>
              <div className="submenu-grid">
                <div className="sub-service-animate-section">
                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faHammer} />
                    </div>
                    <h4 className="title">
                      <a href="#">Custom Woodworking</a>
                    </h4>
                  </div>

                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faScrewdriver} />
                    </div>
                    <h4 className="title">
                      <a href="#">Trim Installation</a>
                    </h4>
                  </div>

                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faRuler} />
                    </div>
                    <h4 className="title">
                      <a href="#">Shelving Solutions</a>
                    </h4>
                  </div>

                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faTools} />
                    </div>
                    <h4 className="title">
                      <a href="#">Deck Construction</a>
                    </h4>
                  </div>
                  <div className="sub-icon-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faPaintRoller} />
                    </div>
                    <h4 className="title">
                      <a href="#">Wood Finishing</a>
                    </h4>
                  </div>
                </div>
                <div className="submenu-form">
                  <div className="contact-form-container">
                    <h2 className="submenu-text">
                      Enter Details to Request<br></br> No-Cost Callback
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Full Name:</label>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Mobile No:</label>
                        <input
                          type="text"
                          placeholder="Enter Mobile Number"
                          value={mobileNo}
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Address:</label>
                        <input
                          type="text"
                          placeholder="Enter Your Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Select Services:</label>
                        <select
                          value={selectedService}
                          onChange={(e) => setSelectedService(e.target.value)}
                        >
                          <option value="">-- Home Deep Cleaning --</option>
                          <option value="Service 1">Home Deep Cleaning</option>
                          <option value="Service 2">Carpentering Work</option>
                          <option value="Service 3">Plumbing Work</option>
                          <option value="Service 3">
                            Used Laptop & Desktop
                          </option>
                          <option value="Service 3">Carpet Shampooing</option>
                          <option value="Service 3">Chair Shampooing</option>
                          <option value="Service 3">Sofa Shampooingk</option>
                          <option value="Service 3">Marble polishing</option>
                          <option value="Service 3">
                            Industrial Deep Cleaning
                          </option>
                          <option value="Service 3">Sales & Services</option>
                          <option value="Service 3">Plumbing Work</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Date & Time:</label>
                        <input
                          type="datetime-local"
                          value={dateTime}
                          onChange={(e) => setDateTime(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>City:</label>
                        <input
                          type="text"
                          placeholder="Enter Your City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="submit-button">
                        Submit Now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
