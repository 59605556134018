import React from "react";
import "./About.css"; // Import CSS file for styling
import about from "../Assets/about.jpg"
const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="text-container">
        <h2>About Us</h2>
        <p>
          Welcome to <b>R2 Cleaning Services</b>, your trusted partner for premier
          corporate and residential cleaning services. With a commitment to
          excellence and a passion for cleanliness, we strive to exceed your
          expectations with every interaction.
        </p>
        <p>
          At <b>R2 Cleaning Services</b>, we understand the importance of a <b>clean and
          healthy environment</b>, whether it’s your office space or your home. Our
          team of <b>highly trained professionals</b> is dedicated to delivering
          top-notch cleaning solutions tailored to meet your specific needs.
        </p>
        <p>
          When you choose <b>R2 Cleaning Services</b>, you’re not just getting a
          cleaning service; you’re partnering with a team that genuinely cares
          about your satisfaction and the cleanliness of your environment.
          Experience the difference with R2 Cleaning Services. Contact us today
          to learn more about our services and let us make your space shine.
        </p>
      </div>
      <div className="image-container">
        <img src={about} alt="About Us Image" />
      </div>
    </div>
  );
};

export default AboutUs;
