import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaKitchenSet } from "react-icons/fa6";
import HouseCleaning from "../Assets/house-cleaning1.jpg";
import painting from "../Assets/house-cleaning.jpg";
import elecrical from "../Assets/elecrical.jpg";
import { Link } from "react-router-dom";
import {
  faHome,
  faPaintBrush,
  faBug,
  faGlobe,
  faSprayCan,
  faWrench,
  faToolbox,
  faShieldAlt,
  faBath,
  faUtensils,
  faCouch,
  faBrush,
  faPaintRoller,
  faWarehouse,
  faMountain,
  faBuilding,
  faBolt,
  faChair,
  faShower,
  faTools,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
import "./ServiceIcons.css";
function ServiceIcons() {
  const services = [
    { icon: faHome, text: "Home Cleaning" },
    { icon: faPaintBrush, text: "Home Painting" },
    { icon: faBug, text: "Pest Control" },
    { icon: faToolbox, text: "Marble Polish" },
    { icon: faSprayCan, text: "Sanitization" },
    // { icon: faWrench, text: "AC Service and Repair" },
    // { icon: faToolbox, text: "Fridge Repairing" },
    { icon: faToolbox, text: "Washing Machine Repairing" },
    { icon: faGlobe, text: "Facility Management Service" },
    { icon: faGlobe, text: "Integrated Management Service" },
    { icon: faBath, text: "Bathroom Deep Cleaning" },
    // { icon: faShieldAlt, text: "Corporate Security" },
  ];

  return (
    <>
      <h1 className="service-headline">Our Services</h1>
      <div className="service-icons">
        {services.map((service, index) => (
          <div key={index} className="service">
            <FontAwesomeIcon icon={service.icon} className="icons-map" />
            <p style={{ fontWeight: "500", padding: "2px" }}>{service.text}</p>
          </div>
        ))}
      </div>
      <section id="services" className="services">
        <div className="container">
          <h1>Cleaning Services</h1>

          <div className="service-animate-container">
            <div className="services-images-section">
              <img
                className="service-images"
                src={HouseCleaning}
                alt="House Cleaning Image"
              ></img>
              <div className="text-container">
                <h4>Home Cleaning</h4>
                <p>
                  Maintaining a clean and tidy environment is essential for both
                  residential and commercial spaces. However, with busy
                  schedules and numerous responsibilities, finding the time to
                  clean thoroughly can be a challenge. That's where professional
                  cleaning services come in to save the day.
                </p>
              </div>
            </div>

            <div className="service-animate-section">
              <div className="icon-box">
                <Link to="/cleaning">
                  <div className="icon">
                    <FontAwesomeIcon icon={faSprayCan} />
                  </div>
                  <h4 className="title">
                    <a href="#">Home Cleaning</a>
                  </h4>
                </Link>
              </div>

              <div className="icon-box">
                <Link to="/carpentering">
                  <div className="icon">
                    <FontAwesomeIcon icon={faTools} />
                  </div>
                  <h4 className="title">
                    <a href="#">Carpentering Work</a>
                  </h4>
                </Link>
              </div>

              <div className="icon-box">
                <Link to="/plumbing">
                  <div className="icon">
                    <FontAwesomeIcon icon={faShower} />
                  </div>
                  <h4 className="title">
                    <a href="#">Plumbing Work</a>
                  </h4>
                </Link>
              </div>

              <div className="icon-box">
                <Link to="/sofashapooing">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCouch} />
                  </div>
                  <h4 className="title">
                    <a href="#">Sofa Cleaning</a>
                  </h4>
                </Link>
              </div>
              <div className="icon-box">
                <Link to="/chair shampooing">
                  <div className="icon">
                    <FontAwesomeIcon icon={faChair} />
                  </div>
                  <h4 className="title">
                    <a href="#">Chair Shampooing</a>
                  </h4>
                </Link>
              </div>

              {/* Repeat these two divs for each additional box */}
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services">
        <div className="container">
          <h1>Painting Services</h1>

          <div className="service-animate-container">
            <div className="services-images-section">
              <img
                className="service-images"
                src={painting}
                alt="House Cleaning Image"
              ></img>
              <div className="text-container">
                <h4>Paintings</h4>
                <p>
                  Whether you're looking to refresh the interior of your home or
                  enhance the curb appeal of your property, professional
                  painting services offer the expertise and craftsmanship needed
                  to achieve stunning results. From interior walls to exterior
                  facades, painting is a versatile and effective way to
                  transform your space and breathe new life into your
                  surroundings.
                </p>
              </div>
            </div>

            <div className="service-animate-section">
              <div className="icon-box">
                <Link to="/interior">
                  <div className="icon">
                    <FontAwesomeIcon icon={faPaintRoller} />
                  </div>
                  <h4 className="title">
                    <a href="#">Interior Painting</a>
                  </h4>
                </Link>
              </div>

              <div className="icon-box">
                <Link to="/vacant">
                  <div className="icon">
                    <FontAwesomeIcon icon={faBuilding} />
                  </div>
                  <h4 className="title">
                    <a href="#">Vacant Painting</a>
                  </h4>
                </Link>
              </div>

              <div className="icon-box">
                <Link to="/exterior">
                  <div className="icon">
                    <FontAwesomeIcon icon={faWarehouse} />
                  </div>
                  <h4 className="title">
                    <a href="#">Exterior Painting</a>
                  </h4>
                </Link>
              </div>

              <div className="icon-box">
                <Link to="/texture">
                  <div className="icon">
                    <FontAwesomeIcon icon={faMountain} />
                  </div>
                  <h4 className="title">
                    <a href="#">Interior Texture</a>
                  </h4>
                </Link>
              </div>

              {/* Repeat these two divs for each additional box */}
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services">
        <div className="container">
          <h1>Electrical Services</h1>

          <div className="service-animate-container">
            <div className="services-images-section">
              <img
                className="service-images"
                src={elecrical}
                alt="House Cleaning Image"
              ></img>
              <div className="text-container">
                <h4>Electrical</h4>
                <p>
                  Electrical services encompass a broad range of tasks and
                  expertise vital for ensuring safe and efficient electrical
                  systems in homes, businesses, and industries. From
                  installation to maintenance and repair, electrical services
                  cover everything related to electrical power and systems.
                  Overall, electrical services play a crucial role in ensuring
                  the safety, reliability, and efficiency of electrical systems,
                  contributing to the smooth functioning of homes, businesses,
                  and industries."
                </p>
              </div>
            </div>

            <div className="service-animate-section">
              <div className="icon-box">
                <Link to="/maintenance">
                  <div className="icon">
                    <FontAwesomeIcon icon={faBolt} />
                  </div>
                  <h4 className="title">
                    <a href="#">Electrical Maintenance</a>
                  </h4>
                </Link>
              </div>

              {/* <div className="icon-box">
                <div className="icon">
                  <FontAwesomeIcon icon={faBolt} />
                </div>
                <h4 className="title">
                  <a href="#">Industrial Electrical Services</a>
                </h4>
              </div> */}

              <div className="icon-box">
                <Link to="/rewiring">
                  <div className="icon">
                    <FontAwesomeIcon icon={faPlug} />
                  </div>
                  <h4 className="title">
                    <a href="#">Rewiring Works</a>
                  </h4>
                </Link>
              </div>

              <div className="icon-box">
                <Link to="/circuit">
                  <div className="icon">
                    <FontAwesomeIcon icon={faTools} />
                  </div>
                  <h4 className="title">
                    <a href="#">Troubleshooting</a>
                  </h4>
                </Link>
              </div>

              {/* Repeat these two divs for each additional box */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceIcons;
