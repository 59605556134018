import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "../Assets/banner2.jpg";
import painting from "../Assets/painting.jpg";
import { Link } from "react-router-dom";
import {
  faPaintRoller,
  faWarehouse,
  faMountain,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

export default function HomePainting() {
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [city, setCity] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      fullName,
      mobileNo,
      address,
      selectedService,
      dateTime,
      city,
    });
    setFullName("");
    setMobileNo("");
    setAddress("");
    setSelectedService("");
    setDateTime("");
    setCity("");
  };
  return (
    <>
      <div className="submenu-section">
        <div className="submenu-contaner">
          <img className="submenu-banner" src={banner}></img>
        </div>
        <section id="services" className="services">
          <div className="container">
            <h1>Home Painting Services</h1>

            <div className="service-animate-container">
              <div className="services-images-section">
                <img
                  className="service-images"
                  src={painting}
                  alt="House Cleaning Image"
                ></img>
                <div className="text-container">
                  <h4>Home Painting</h4>
                  <p>
                    Home painting, an art form that breathes life into walls and
                    spaces, goes beyond mere color application. It's a
                    transformative process that can rejuvenate a room, evoke
                    emotions, and reflect personal style. At its essence, home
                    painting is about more than just selecting colors. It's
                    about understanding the psychology of color and how
                    different hues can influence mood and atmosphere. From
                    calming blues to energizing yellows, each color choice can
                    shape the ambiance of a space and enhance its functionality.
                  </p>
                </div>
              </div>
              <div className="submenu-grid">
                <div className="sub-service-animate-section">
                  <div className="sub-icon-box">
                    <Link to="/interior">
                      <div className="icon">
                        <FontAwesomeIcon icon={faPaintRoller} />
                      </div>
                      <h4 className="title">
                        <a href="#">Interior Painting</a>
                      </h4>
                    </Link>
                  </div>

                  <div className="sub-icon-box">
                    <Link to="/vacant">
                      <div className="icon">
                        <FontAwesomeIcon icon={faBuilding} />
                      </div>
                      <h4 className="title">
                        <a href="#">Vacant Painting</a>
                      </h4>
                    </Link>
                  </div>

                  <div className="sub-icon-box">
                    <Link to="/exterior">
                      <div className="icon">
                        <FontAwesomeIcon icon={faWarehouse} />
                      </div>
                      <h4 className="title">
                        <a href="#">Exterior Painting</a>
                      </h4>
                    </Link>
                  </div>

                  <div className="sub-icon-box">
                    <Link to="/texture">
                      <div className="icon">
                        <FontAwesomeIcon icon={faMountain} />
                      </div>
                      <h4 className="title">
                        <a href="#">Interior Texture</a>
                      </h4>
                    </Link>
                  </div>
                </div>
                <div className="submenu-form">
                  <div className="contact-form-container">
                    <h2 className="submenu-text">
                      Enter Details to Request<br></br> No-Cost Callback
                    </h2>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Full Name:</label>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Mobile No:</label>
                        <input
                          type="text"
                          placeholder="Enter Mobile Number"
                          value={mobileNo}
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Address:</label>
                        <input
                          type="text"
                          placeholder="Enter Your Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>Select Services:</label>
                        <select
                          value={selectedService}
                          onChange={(e) => setSelectedService(e.target.value)}
                        >
                          <option value="">-- Home Deep Cleaning --</option>
                          <option value="Service 1">Home Deep Cleaning</option>
                          <option value="Service 2">Carpentering Work</option>
                          <option value="Service 3">Plumbing Work</option>
                          <option value="Service 3">
                            Used Laptop & Desktop
                          </option>
                          <option value="Service 3">Carpet Shampooing</option>
                          <option value="Service 3">Chair Shampooing</option>
                          <option value="Service 3">Sofa Shampooingk</option>
                          <option value="Service 3">Marble polishing</option>
                          <option value="Service 3">
                            Industrial Deep Cleaning
                          </option>
                          <option value="Service 3">Sales & Services</option>
                          <option value="Service 3">Plumbing Work</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Date & Time:</label>
                        <input
                          type="datetime-local"
                          value={dateTime}
                          onChange={(e) => setDateTime(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label>City:</label>
                        <input
                          type="text"
                          placeholder="Enter Your City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="submit-button">
                        Submit Now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
